import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CoreKitConfig, CoreKitModule } from "core-kit";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routes";
import { TranslocoRootModule } from "./transloco/transloco-root.module";

const coreKitConfig: CoreKitConfig = {
  baseName: environment.baseName,
  apiName: environment.apiName,
  isProduction: environment.production,
  selfServicePortalUrl: environment.selfServicePortalUrl
};

@NgModule({
  declarations: [AppComponent],
  imports: [RouterModule, RouterModule.forRoot(appRoutes), CoreKitModule.forRoot(coreKitConfig), BrowserModule, TranslocoRootModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
